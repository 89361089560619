var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('CommentsSkeleton')]},proxy:true}])},[_c('div',{class:_vm.isMobile() ? 'card-m' : 'card'},[(_vm.authenticated)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addComment(_vm.form.comment)}}},[_c('b-input-group',{staticClass:"mt-1"},[_c('b-textarea',{staticClass:"textarea",attrs:{"type":"text","placeholder":_vm.comments.length == 0
              ? 'Be the first to comment this post'
              : 'What you think?',"keydown.enter.shift.exact":"newline","rows":"1","max-rows":"3","size":"sm","no-resize":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.addComment(_vm.form.comment)}},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('b-input-group-append',[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.properCommentLength)?_c('b-button',{attrs:{"variant":"outline-light","type":"submit","disabled":!_vm.properCommentLength}},[_vm._v("Say")]):_vm._e()],1)],1)],1),(_vm.commentLength > 0)?_c('div',[_c('small',{style:(_vm.properCommentLength ? 'color: white' : 'color: red')},[_vm._v(" "+_vm._s(_vm.commentLength)+"/"+_vm._s(_vm.maxCommentLength)+" ")])]):_vm._e()],1):_vm._e(),(!_vm.authenticated)?_c('div',{staticClass:"border border-dark rounded p-2"},[_c('b-row',[_c('b-col',[_c('span',[_vm._v("Want to leave a comment?")])]),_c('b-col',{staticClass:"mr-3",attrs:{"cols":"end"}},[_c('b-container',{staticClass:"p-0 m-0",attrs:{"tag":_vm.isMobile() ? 'div' : 'span'}},[_c('b-button',{class:{ 'mr-2': !_vm.isMobile() },attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Auth',
                  params: {
                    signUpPage: true,
                    prevRoute: _vm.$route.path,
                  },
                })}}},[_vm._v("Sign Up ")])],1),_c('b-container',{staticClass:"p-0 m-0",class:{ 'mt-2': _vm.isMobile() },attrs:{"tag":_vm.isMobile() ? 'div' : 'span'}},[_c('b-button',{attrs:{"variant":"outline-info","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Auth',
                  params: {
                    prevRoute: _vm.$route.path,
                  },
                })}}},[_vm._v("Sign In ")])],1)],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',[(_vm.comments.length > 1)?_c('b-input-group',{staticClass:"mt-1"},[_c('b-dropdown',{attrs:{"disabled":_vm.requesting,"size":"sm","variant":"dark","text":_vm.options.active.text}},_vm._l((_vm.options.filters),function(option,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":_vm.options.active.text === option.text},on:{"click":function($event){return _vm.order(option)}}},[_vm._v(_vm._s(option.text))])}),1),_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"dark","disabled":true},on:{"click":function($event){return this.getComments()}}},[_c('b-icon',{attrs:{"icon":"arrow-counterclockwise"}})],1)],1):_vm._e()],1)],1),_c('CommentGroup',{attrs:{"comments":_vm.comments}}),_c('b-row',{attrs:{"no-gutters":"","align-h":"center"}},[(_vm.totalRows > _vm.loadedRows)?_c('b-button',{attrs:{"size":"sm","variant":"dark"},on:{"click":function($event){return _vm.getComments()}}},[_vm._v("load "+_vm._s(_vm.totalRows - _vm.loadedRows)+" more "+_vm._s((_vm.totalRows - _vm.loadedRows) % 10 !== 1 ? "comments" : "comment")+" ")]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }